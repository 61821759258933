const INITIAL_STATE = {
	input: '',
	online: true,
	activeTab: 'detran',
	processoType: 'default',
	customType: null,
	processo: '',
	reports: {},
	ttl: ''
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'APP_CHANGE_INPUT':
			return { ...state, input: action.payload };
		case 'APP_CHANGE_STATUS':
			return { ...state, online: action.payload };
		case 'APP_CHANGE_PROCESSO':
			return { ...state, processo: action.payload };
		case 'APP_CLEAR_PROCESSO':
			return { ...state, processo: '' };
		case 'APP_CHANGE_PROCESSO_TYPE':
			return { ...state, processoType: action.payload };
		case 'APP_CHANGE_REPORTS':
			localStorage.setItem('reports', JSON.stringify(action.payload));
			return { ...state, reports: action.payload };
		case 'APP_CHANGE_REPORT':
			localStorage.setItem('reports', JSON.stringify({...state.reports, ...action.payload}));
			return { ...state, reports: {...state.reports, ...action.payload} };
		case 'APP_CHANGE_CUSTOM_TYPE':
			return { ...state, customType: action.payload, input: '' };
		case 'APP_DELETE_REPORT':
			const reports = { ...state.reports };
			delete reports[action.payload];
			return { ...state, reports: reports };
		case 'APP_RENEW_TTL':
			const ttl = 20 * 60000;
			if (state.ttl) {
				clearTimeout(state.ttl);
			}
			localStorage.setItem('ttl', Date.now() + ttl);
			return { ...state, ttl: setTimeout(() => {window.location.href = '/#/logout'}, ttl) };
		case 'TAB_CHANGE':
			if ( state.activeTab !== action.payload) {
				return { ...state, activeTab: action.payload };
			}
			return state;
		default:
			return state;
	}
};